import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./NielsShoeMeulman.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import NielsShoeMeulmanPhoto1 from "../../../../res/Photos site/Niels Shoe Meulman/shoe.jpeg"
import NielsShoeMeulmanPhoto2 from "../../../../res/Photos site/Niels Shoe Meulman/1_NIELS_SHOE_MEULMAN_by_Søren_Solkær_Starbird.jpg"
import NielsShoeMeulmanPhoto3 from "../../../../res/Photos site/Niels Shoe Meulman/Niels Shoe Throne .webp"
import NielsShoeMeulmanPhoto4 from "../../../../res/Photos site/Niels Shoe Meulman/Niels, Shoe Throne, vue in situ.png BD.png"
import NielsShoeMeulmanPhoto5 from "../../../../res/Photos site/Niels Shoe Meulman/shoe.png"
import NielsShoeMeulmanPhoto6 from "../../../../res/Photos site/Niels Shoe Meulman/Vue in situ .webp"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/shoe/portrait.jpg'
import PastPresent1 from "../../../../res/shoe/past-present-1.jpg"
import PastPresent2 from "../../../../res/shoe/past-present-2.jpg"
import PastPresent3 from "../../../../res/shoe/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "NielsShoeMeulman",
  name: "NielsShoeMeulman",
  description:"Niels Shoe Meulman est né en 1967 à Amsterdam et est un artiste pionnier du graffiti européen des années 1980. Ce que Shoe canalise dans l’espace et dans le temps n’est pas un nouveau système d’alphabet, mais bien une invitation à redevenir spectateur des mots, à étudier les trois forces de leur nature : les caractères individuels et leur arrangement, la signification du mot et les imaginations de l’artiste. En 1983, avec l’arrivée du hip hop en Europe, Shoe rencontre alors des writers new-yorkais comme Dondi White, Rammellzee et Futura 2000 qui vont renforcer son implication dans le mouvement graffiti. Shoe allie ses différentes passions : le graffiti, l’écriture et la calligraphie qu’il définit comme Calligraffiti, nom de sa première exposition personnelle en 2007 à Amsterdam.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de NielsShoeMeulman.",
  photos: [
    { src: PastPresent1, name: "NielsShoeMeulman" },
    { src: PastPresent2, name: "NielsShoeMeulman" },
    { src: PastPresent3, name: "NielsShoeMeulman" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const NielsShoeMeulman = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>NIELS SHOE MEULMAN <h2 style={{paddingLeft: "16px"}}>(Néerlandais)</h2></h1>
          <h3 className={NameWrapper} >Né en 1967</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- What The Fuck?, Straat Museum, Amsterdam, Netherlands, (opening) October 15th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Shoes’ Negative Space, Museum of Graffiti, Miami, USA, (opening) December 5th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Unstallation, Ghost Galerie, Marseille, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Niels Shoe Meulman: Unidenticals and Reserve Paintings, Galerie Droste, Wuppertal, Germany, May 20th - July 15th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Uncontrolled Substances, Galerie Gabriel Rolt, Amsterdam, Netherlands, October 15th - November 18th.</p>
          <p>- Nissa la Bella, GCA Gallery, Nice, France, September 23rd - November 26th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Shoe at Flow, Make Your Mark Gallery, Helsinki, Finland.</p>
          <p>- Shoegazing, A4LW gallery, Soest, Germany, October 4th - November 1st.</p>
          <p>- The Unearthly Paintings, White Walls Gallery, San Francisco, USA, July 12th - August 9th.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Unbelieve: An Afternoon with Neils Shoe Meulman, Unit44, Newcastle, UK, August 10th.</p>
          <p>- Niels ‘Shoe’ Meulman: Unism, White Walls Gallery, San Francisco, USA, July 6th - August 10th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- NSM: Justified Scriptures, 941 Geary Gallery, San Francisco, USA, March 24th - April 21st.</p>
          <p>- Oesch, Black Rainbow Gallery, Paris, France</p>
          <p>- Calligraffiti: Upside Down Tour, Kind of Gallery, Sydney, Australia, January 25th - 30th; Rtist Gallery, Melbourne, New Zealand, February 2nd - 12th; Selfridges & Co: Shoe/United Nude collaboration exhibition. Live Calligraffiti on February 25th, London, United Kingdom, February 23rd - March 30th; Saatchi & Saatchi Gallery, Auckland, New-Zealand, February 9th - March 1st; Auckland University of Technology: SHOE (Niels Meulman) Born in 1967 in Amsterdam, Netherlands Lives and works in Amsterdam, Netherlands Presentation/talk, In collaboration with Designers Institute of New Zealand, February 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Calligraffiti, Arty Farty Gallery, Köln, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Throw-Ups, Arkitip project space, Los Angeles, USA.</p>
          <p>- Calligraffiti: Letters of Independence, Salamatina Gallery, Manhasset, New York, USA, July 1st - 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Le Miroir Vivant, Tenue de Nîmes, Amsterdam, Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Different Strokes, Rush Hour, Amsterdam, Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Calligraffiti, Post BG, Amsterdam, Netherlands. </p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Momentum, Audrey Hatchikian Gallery, Paris, France, September 7th - October 31st.</p>
          <p>- Papers & Wood, Audrey Hatchikian Gallery, l’Alternatif Paris la Défense, Paris, France, July 5th - August 31st.</p>
          <p>- Art is Where the Heart is Vol. 2, Galerie Droste Paris, Paris, France, February 15th - 27th. (catalogue)</p>
          <p>- Brick to Canvas: A Survey of International Street Art , Tinney Contemporary, curated by Brian Greif and Tova Lobatz, Tennessee, Nashville, USA, May 7th - 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Work in Progress, St+art Festival, sponsored by the Saffronart Foundation, New Delhi, India, January 31st - February 28th.</p>
          <p>- Graffiti: New York Meets the Dam, The Amsterdam Museum, Amsterdam, Netherlands, September 18th - January 24th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Abstract Vandalism, Galerie Gabriel Rolt, curated by Niels Shoe Meulman, Amsterdam, Netherlands, March 14th - April 18th.</p>
          <p>- SURFACE: Portraits and Paintings of and by Street Artists, Allouche Gallery curated by Søren Solkaer. New York, USA, May 2nd - 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Crime Time Kings: Bando / Mode2 / Shoe, Unruly Gallery presented by Unruly and Wix, Amsterdam, Netherlands, June 27th.</p>
          <p>- The Alphabet Show: Every Letter Counts, White Walls Gallery, San Francisco, USA, May 10th - June 7th.</p>
          <p>- An Even Eleven, White Walls/Shooting Gallery, San Francisco, USA, March 15th - April 5th.</p>
          <p>- Urbanism, Moscow Museu, Moscow, Russia.</p>
          <p>- Wu Tang is for The Children, Londonewcastle Project Space, Shoreditch, UK, March 7th - 14th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Caleidoscoop, mural paintings at the Dutch Pavilion in Wynwood for Art Basel Miami, Florida, USA, December 2nd - 8th.</p>
          <p>- Calligraffiti: 1984 - 2013, Leila Heller Gallery curated by Jeffrey Deitch, New York, USA, September 5th - October 5th.</p>
          <p>- TEDxBrussels: “Ex Nihilo” painting performance on stage, Brussels, Belgium, October 28th.</p>
          <p>- Adobe XD: talk and workshop, San Francisco, USA, October 16th.</p>
          <p>- Trimarchi International Design Conference: lecture and mural, Mar del Plata, Argentina, October 4th - 6th.</p>
          <p>- Graff-ik’Art Festival: live painting with Mode 2, Part One, Jay One and others, Lyon, France, September 22nd - 24th.</p>
          <p>- Bricks & Pieces – The New Politics of Imagination, Able & Baker gallery (Bürgerhaus Stollwerck) with live painting, Koeln, Germany, September 7th - 30th.</p>
          <p>- Exhibition and print release, Unit 44, Newcastle, United Kingdom, August 9th - 11th.</p>
          <p>- Lyrics, Galerie Hélène Bailly, curated by Olivier Dermaux, Paris, France, June 27th - July 27th.</p>
          <p>- MESS, Paris, France, March 21th - April 21th.</p>
          <p>- Repeatclub 02, Witzenhausen Gallery, Amsterdam, Netherlands, opens March 9th.</p>
          <p>- White Walls 10 Year Anniversary Exhibition, White Walls, San Francisco, USA, March 9th - April 6th.</p>
          <p>- Pop-Up: Design Entre les Dimensions, MUDAC curated by Lidewij Edelkoort, Lausanne, France, November 22nd, 2012 - March 3rd, 2013.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Miami Art Basel, Florida, USA</p>
          <p>- The Name Game, Hotel de Goudfazant, Amsterdam, Netherlands.</p>
          <p>- Moniker Art Fair, London, UK.</p>
          <p>- Nuart Festival: in/outdoor installation, Stavanger, NO Team Rex, London, UK.</p>
          <p>- &friends, Galerie Lefeuvre, Paris, France, May 24th - June 24th.</p>
          <p>- Shoe / United Nude collaboration, Selfridges & Co. London, UK, February 23rd - March 30th.</p>
          <p>- Pop-Up, Graphic Design Museum curated by Lidewij Edelkoort, Live Calligraffiti at the opening, Breda, Netherlands, December 11th, 2011 - April 16th, 2012.</p>
          <p>- COMMA, Pallazo della Penna, Perugia, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Le Lieu Unique, Nantes, France.</p>
          <p>- Off Canvas, Beijing, China.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Where On Earth Have You Been, Bright Trade Show, Frankfurt, Germany.</p>
          <p>- Salon de l’Amitié, Arti, Amsterdam, Netherlands.</p>
          <p>- Imagining Mozambique, Maxalot Pop-Up Gallery, Amsterdam, Netherlands, August 27th - September 10th.</p>
          <p>- Imagining Mozambique, travelling fundraising drive for ASEM, MassMarket Gallery, New York, USA, October 26th - November 1st.</p>
          <p>- SPEAKER at TEDxBrussels, European Parliament, Brussels, Belgium.</p>
          <p  id="end">- Flyoing Eyeball, curated by Inkie, London, United Kingdom, December 8th - 12th.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default NielsShoeMeulman;