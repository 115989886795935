// extracted by mini-css-extract-plugin
export var ArtistDescription = "NielsShoeMeulman-module--ArtistDescription--Xdpmy";
export var ArtistInfos = "NielsShoeMeulman-module--ArtistInfos--zVo5H";
export var ButtonWrapper = "NielsShoeMeulman-module--ButtonWrapper --zxFLR";
export var CardWrapper = "NielsShoeMeulman-module--CardWrapper--A4kbZ";
export var CarrouselWrapper2 = "NielsShoeMeulman-module--CarrouselWrapper2--6aKok";
export var Citations = "NielsShoeMeulman-module--Citations--wmy7M";
export var DescriptionWrapper = "NielsShoeMeulman-module--DescriptionWrapper--PbcYb";
export var ImageWrapper = "NielsShoeMeulman-module--ImageWrapper--6ogpC";
export var LinkWrapper = "NielsShoeMeulman-module--LinkWrapper--rrC+T";
export var MobileProtrait = "NielsShoeMeulman-module--MobileProtrait--5HUHm";
export var More = "NielsShoeMeulman-module--More--1agcp";
export var MoreButton = "NielsShoeMeulman-module--MoreButton--wAKeH";
export var NameWrapper = "NielsShoeMeulman-module--NameWrapper--borgj";
export var PdpWrapper = "NielsShoeMeulman-module--PdpWrapper--oEuVK";
export var PhotosWrapper = "NielsShoeMeulman-module--PhotosWrapper--mUA8L";
export var ProfilWrapper = "NielsShoeMeulman-module--ProfilWrapper--4tyBK";
export var TitleWrapper = "NielsShoeMeulman-module--TitleWrapper--KuFvY";
export var Wrapper = "NielsShoeMeulman-module--Wrapper--fElHh";